import { useEffect } from 'react';
import ReactPlayer from 'react-player';
import { getText } from '@veraio/strank';
import {
  Button,
  Col,
  Image,
  Row,
  BecomeAffiliateCart,
  CarouselProducts,
  IngredientsSlider,
  ProductsSlider,
  useWindowSize,
} from 'components';
import { ALL_PRODUCTS_MOCK_DATA, ROUTES, ROUTINE_PRODUCTS_DATA } from 'enums';
import { ingredientsData } from './config';
import { homePageContainer } from './styles';

const HomePage = ({ isMobile }) => {
  const windowWidth = useWindowSize(window.innerWidth, window.innerHeight);
  const tabletWidth = 992 > windowWidth.width && windowWidth.width > 767;
  const notTabletNotMobile = 767 > windowWidth.width && windowWidth.width > 576;
  const mobileWidth = windowWidth.width < 576;

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div css={homePageContainer(isMobile)}>
      <ReactPlayer
        url={
          mobileWidth
            ? 'https://vimeo.com/1012780622/ca26b5e0fb?share=copy'
            : tabletWidth || isMobile
            ? 'https://vimeo.com/1012779936/4b66854c70?share=copy'
            : 'https://vimeo.com/1012788606/045ec5fe20?share=copy'
        }
        height={
          windowWidth.width > 1399 ? 'calc(100vh - 60px)' : notTabletNotMobile ? 'auto' : tabletWidth ? '100%' : '100%'
        }
        width="100vw"
        playing={true}
        loop={true}
        config={{
          vimeo: {
            playerOptions: {
              autoplay: true,
              fullscreen: true,
              responsive: true,
            },
          },
        }}
      />
      <div className="crystal-image-container">
        <Image
          src="https://oneecosystem.blob.core.windows.net/vita/apps/images/silver-crystal.svg"
          className="crystal-image"
        />
        <div className="text-over-crystal-container">
          <p className="title-company">{getText('oneVita')}</p>
          <h4 className="title-beauty">{getText('secretToTimelessBeauty')}</h4>
          <p className="description">{getText('homeProductsDescription')}</p>
          <Button type="secondary" className="secondary-button" linkTo={ROUTES.Products}>
            {getText('exploreProducts')}
          </Button>
        </div>
      </div>

      <div className="from-nature-container">
        <p className="upper-text">{getText('elevateYourGlow')}</p>
        <h4 className="title">{getText('healthAndBeautyFromNature')}</h4>
        <ProductsSlider products={ALL_PRODUCTS_MOCK_DATA} className="product-slider" isMobile={isMobile} />
      </div>

      <Row className="about-container">
        <Col xl={6} lg={6} md={6} sm={12} xs={12}>
          <Image src="https://oneecosystem.blob.core.windows.net/vita/apps/images/about.svg" className="about-image" />
        </Col>
        <Col xl={6} lg={6} md={6} sm={12} xs={12} className="about-info">
          <p className="upper-text">{getText('aboutOneVita')}</p>
          <h4 className="title">{getText('smallButMighty')}</h4>
          <p className="description-about">{getText('aboutVitaDescriptionHomePage')}</p>
          <Button type="secondary" className="about-secondary-button" linkTo={ROUTES.BrandStory}>
            {getText('ourStory')}
          </Button>
        </Col>
      </Row>

      <div className="routine-steps-container">
        <p className="upper-text">{getText('fiveStepSystem')}</p>
        <h4 className="title">{getText('forYouthfulSkin')}</h4>
        <CarouselProducts products={ROUTINE_PRODUCTS_DATA} isMobile={isMobile} />
      </div>

      <div className="secrets">
        <Row className="secrets-container" horizontalGap={24}>
          <Col xl={4} lg={4} md={12} xs={12}>
            <h4 className="title-secrets">{getText('theSecretOfOneVita')}</h4>
          </Col>
          <Col xl={8} lg={8} md={12} xs={12} className="secrets-description-container">
            <p className="description-secrets">{getText('revolutionaryMicroSizedMolecules')}</p>
          </Col>
        </Row>
        <IngredientsSlider ingredients={ingredientsData} />
        <div className="secrets-button-container">
          <Button type="secondary" className="secondary-button" linkTo={ROUTES.Ingredients}>
            {getText('learnMore')}
          </Button>
        </div>
      </div>

      <BecomeAffiliateCart />
    </div>
  );
};

export default HomePage;
