import { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { div, mul } from 'exact-math';
import { Col, Icon, ProductSliderCart } from 'components';
import { productsContainer, dotsContainer, indicatorDot } from './styles';

const ProductsSlider = ({ products, isMobile, className }) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const scrollContainerRef = useRef();
  const cardRef = useRef();
  const showCartInContainer = div(scrollContainerRef.current?.clientWidth ?? 0, cardRef.current?.clientWidth ?? 0);
  const cart = Math.floor(showCartInContainer);

  useEffect(() => {
    scrollContainerRef?.current?.scrollTo({
      left: mul(currentIndex, cardRef.current?.clientWidth ?? 0),
      behavior: 'smooth',
      top: 0,
    });
  }, [currentIndex]);

  const handlePrevSlide = () =>
    setCurrentIndex((prevIndex) => (prevIndex !== 0 ? prevIndex - 1 : products.length - cart));

  const handleNextSlide = () =>
    setCurrentIndex((prevIndex) => (prevIndex !== products.length - cart ? prevIndex + 1 : 0));

  const getDotsTranslate = () => {
    switch (true) {
      case currentIndex < 1:
        return 0;
      case currentIndex > products.length - 2:
        return (products.length - 3) * -14;
      default:
        return (currentIndex - 1) * -14;
    }
  };

  return (
    <div css={productsContainer} {...(className && { className })}>
      {currentIndex !== 0 && (
        <Icon material size={30} onClick={handlePrevSlide} iconName="arrow_circle_left" className="arrow-left" />
      )}

      <div className="products-badge-container" ref={scrollContainerRef}>
        {products?.map((el, ind) => (
          <Col xl={4} lg={6} md={6} sm={6} xs={12} key={ind} className="column-cart" ref={cardRef}>
            <ProductSliderCart product={el} />
          </Col>
        ))}
      </div>

      {isMobile && (
        <div css={dotsContainer} className="dots-container">
          <ul className="dots-list" style={{ transform: `translateX(${getDotsTranslate()}px)` }}>
            {products?.map((_, index) => (
              <li
                key={index}
                css={indicatorDot(index === currentIndex)}
                className="indicator-dot"
                onClick={() => setCurrentIndex(index)}
                role="presentation"
              />
            ))}
          </ul>
        </div>
      )}

      {products?.length - cart !== currentIndex && (
        <Icon material size={30} onClick={handleNextSlide} iconName="arrow_circle_right" className="arrow-right" />
      )}
    </div>
  );
};

ProductsSlider.propTypes = {
  products: PropTypes.array,
  isMobile: PropTypes.bool,
  className: PropTypes.string,
};

export default ProductsSlider;
