export const affiliateData = [
  {
    id: 1,
    image: 'https://oneecosystem.blob.core.windows.net/vita/apps/images/coin-icon.svg',
    title: 'directAndNetworkBonuses',
    description: 'earnLucrativeRewardsForYourDirectSales',
  },
  {
    id: 2,
    image: 'https://oneecosystem.blob.core.windows.net/vita/apps/images/delivery-icon.svg',
    title: 'freeDelivery',
    description: 'enjoyComplimentaryDelivery',
  },
  {
    id: 3,
    image: 'https://oneecosystem.blob.core.windows.net/vita/apps/images/education-icon.svg',
    title: 'trainingAndEducation',
    description: 'gainExclusiveAccessToOurComprehensiveTraining',
  },
  {
    id: 1,
    image: 'https://oneecosystem.blob.core.windows.net/vita/apps/images/growing-icon.svg',
    title: 'growingOpportunity',
    description: 'embarkOnJourneyWithEndlessPossibilities',
  },
];
