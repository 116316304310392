import { theme } from 'styles';

export const skinCareContainer = (isMobile) => ({
  '.header-image': {
    width: '100vw',
    height: isMobile ? '100%' : 400,
  },

  '.product-button': {
    textTransform: 'uppercase',
    marginBottom: 36,
    backgroundColor: 'transparent',
    color: theme.gray500,
    border: `1px solid ${theme.grayLight}`,
    fontSize: 16,
    fontWeight: 400,
    width: 'fit-content',

    '&:active': { outline: 'none' },

    '@media (max-width: 767px)': {
      width: '100%',
    },
  },

  '.header-info-container': {
    backgroundColor: theme.secondary,
    padding: '3em 9.25em ',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    flexWrap: 'wrap',

    '.title': {
      fontSize: 38,
      fontWeight: 400,
      color: theme.black,
      width: '40%',

      '@media (max-width: 767px)': {
        width: '100%',
        marginBottom: 24,
      },
    },

    '.subtitle': {
      fontSize: 18,
      fontWeight: 300,
      color: theme.gray900,
      width: '40%',
      lineHeight: 1.5,

      '@media (max-width: 767px)': {
        width: '100%',
      },
    },

    '@media (max-width: 767px)': {
      padding: '3em',
    },
  },

  '.routine-steps-container': {
    padding: '3em 9.25em 1em',

    '.routine-title': {
      fontSize: 28,
      fontWeight: 400,
      color: theme.black,

      '@media (max-width: 767px)': {
        marginBottom: 32,
      },
    },

    '.routine-container': {
      padding: '8em 0 0 4em',

      '.bordered-container': {
        borderLeft: `1px solid ${theme.black24}`,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',

        '.buy-routine': {
          textTransform: 'uppercase',
          margin: '40px 0',
          fontWeight: 400,
          padding: '1em 4em',
          width: 'fit-content',
        },

        '@media (max-width: 767px)': {
          borderLeft: 'none',
        },
      },

      '.mobile-content-container': {
        textAlign: 'center',
        marginBottom: 32,

        '.mobile-images': {
          width: '100%',
          maxWidth: 500,
          maxHeight: 515,
        },
      },

      '.step': {
        backgroundColor: theme.black,
        color: theme.white,
        borderRadius: '50%',
        width: 100,
        height: 100,
        fontSize: 48,
        position: 'absolute',
        left: 110,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',

        '@media (max-width: 992px)': {
          width: 80,
          height: 80,
          fontSize: 40,
          left: 120,
        },

        '@media (max-width: 767px)': {
          position: 'relative',
          width: 60,
          height: 60,
          fontSize: 36,
          left: 0,
          marginRight: 16,
        },
      },

      '.product-container': {
        display: 'flex',
        marginBottom: 84,

        '.image': {
          width: '100%',
          maxWidth: 350,
          height: '100%',
        },
      },

      '.products-info-container': {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'flex-start',

        '.mobile-product-title': {
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          marginBottom: 16,
        },

        '.title': {
          fontSize: 32,
          fontWeight: 400,
          marginBottom: 8,

          '@media (max-width: 992px)': {
            fontSize: 28,
          },

          '@media (max-width: 767px)': {
            fontSize: 24,
          },
        },

        '.horizontal-line': {
          backgroundColor: theme.black,
          width: '100%',
          marginBottom: 16,
        },

        '.description': {
          fontSize: 20,
          fontWeight: 300,
          marginBottom: 48,

          '@media (max-width: 992px)': {
            fontSize: 18,
            marginBottom: 32,
          },
        },

        '@media (max-width: 767px)': {
          width: '100%',
          paddingRight: 0,
        },
      },

      '@media (max-width: 767px)': {
        padding: '3em 2em',
      },
    },

    '@media (max-width: 767px)': {
      padding: '3em',
    },
  },

  '.horizontal-end-routine-line': {
    backgroundColor: theme.black,
    width: '100%',
    marginBottom: 16,

    '@media (max-width: 767px)': {
      display: 'none',
    },
  },
});
