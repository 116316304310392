import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useTranslations } from '@veraio/strank';
import { Button, Icon, Image, Languages, User } from 'components';
import { ALL_PRODUCTS_MOCK_DATA, ROUTES } from 'enums';
import { useUserStore } from 'stores';
import { ReactComponent as Logo } from 'assets/images/logo.svg';
import { button, navBarHeaderContainer, navBarLeftSideContainer, navBarRightSideContainer } from './styles';

const NavBar = () => {
  const history = useHistory();
  const { getText } = useTranslations();
  const [active, setActive] = useState(null);
  const isAuthenticated = useUserStore((userState) => userState.isAuthenticated);

  useEffect(() => {
    setActive(history.location?.pathname);
  }, [history.location?.pathname]);

  const handleProductClick = () => {
    history.push(ROUTES.Products);
    setActive(ROUTES.Products);
  };

  const handleSelectProduct = (event, product) => {
    event.stopPropagation();
    history.push({ pathname: `/products/${product.id}`, state: product });
  };

  return (
    <header css={navBarHeaderContainer(ROUTES.Products === active)}>
      <div css={navBarLeftSideContainer}>
        <Logo
          onClick={() => {
            history.push(ROUTES.Home);
            setActive(ROUTES.Home);
          }}
        />
      </div>

      <div className="buttons-container">
        <div className="products">
          <div role="button" tabIndex={0} onClick={handleProductClick} className="product-button">
            {getText('products')}

            <div className="hovered-content-container">
              <h3 className="title">{getText('smallButMightyUnlockPower')}</h3>
              <div className="product-container">
                {ALL_PRODUCTS_MOCK_DATA.map((el) => (
                  <div
                    key={el.id}
                    className="product"
                    role="button"
                    tabIndex={0}
                    onClick={(event) => handleSelectProduct(event, el)}>
                    <Image src={el.imageNav} className="image" />
                    <p className="product-name">{getText(el.navTitle)}</p>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
        <Button
          type="link"
          linkTo={ROUTES.SkinCare}
          onClick={() => setActive(ROUTES.SkinCare)}
          css={button(ROUTES.SkinCare === active)}>
          {getText('skinCare')}
        </Button>
        <Button
          type="link"
          linkTo={ROUTES.BrandStory}
          onClick={() => setActive(ROUTES.BrandStory)}
          css={button(ROUTES.BrandStory === active)}>
          {getText('brandStory')}
        </Button>
        {isAuthenticated ? (
          <Button
            type="link"
            linkTo={ROUTES.MyAffiliates}
            onClick={() => setActive(ROUTES.MyAffiliates)}
            css={button(ROUTES.MyAffiliates === active)}>
            {getText('myAffiliates')}
          </Button>
        ) : (
          <Button
            type="link"
            linkTo={ROUTES.BecomeAnAffiliate}
            onClick={() => setActive(ROUTES.BecomeAnAffiliate)}
            css={button(ROUTES.BecomeAnAffiliate === active)}>
            {getText('becomeAnAffiliate')}
          </Button>
        )}
      </div>

      <div css={navBarRightSideContainer}>
        <Icon iconName="shopping_cart" className="icon" />
        <Icon iconName="apps" className="icon" />
        <Languages className="languages-container" />
        {isAuthenticated ? (
          <User />
        ) : (
          <Icon iconName="login" className="icon" onClick={() => history.push(ROUTES.Login)} />
        )}
      </div>
    </header>
  );
};

NavBar.propTypes = {
  logoLink: PropTypes.string,
};

export default NavBar;
