import { AFFILIATE_STATUS } from 'enums';

export const affiliateStatusMap = {
  [AFFILIATE_STATUS.PENDING]: {
    color: 'pink',
    title: 'sitTight',
    description: 'weAreStillEvaluatingYourApplication',
  },
  [AFFILIATE_STATUS.APPROVED]: {
    color: 'success',
  },
  [AFFILIATE_STATUS.REJECTED]: {
    color: 'error',
    title: 'yourApplicationHasBeenRejected',
    description: 'weRegretToInformYouButYourApplicationHasBeenRejected',
  },
};

export const affiliateProducts = [
  {
    id: 1,
    image: 'https://oneecosystem.blob.core.windows.net/vita/apps/images/cleaning-gel.jpg',
    title: 'liposomalCleansingGel',
    link: 'onevita.eu/r/231fasvs-23b-gag-w25',
  },
  {
    id: 2,
    image: 'https://oneecosystem.blob.core.windows.net/vita/apps/images/filler-serum.jpg',
    title: 'liposomalFillerSerum',
    link: 'onevita.eu/r/loo1fasvs-2387urg-w25',
  },
  {
    id: 3,
    image: 'https://oneecosystem.blob.core.windows.net/vita/apps/images/eye.jpg',
    title: 'liposomalEyeCream',
    link: 'onevita.eu/r/52316vD-3b-gag-w25',
  },
  {
    id: 4,
    image: 'https://oneecosystem.blob.core.windows.net/vita/apps/images/day-cream.svg',
    title: 'liposomalDayCream',
    link: 'onevita.eu/r/231fasvs-23b-gag-w25',
  },
  {
    id: 5,
    image: 'https://oneecosystem.blob.core.windows.net/vita/apps/images/night-cream.jpg',
    title: 'liposomalNightCream',
    link: 'onevita.eu/r/bl4-522xasvs-23b-g7v25b',
  },
  {
    id: 6,
    image: 'https://oneecosystem.blob.core.windows.net/vita/apps/images/night-cream.jpg',
    title: 'liposomalCalmingAndHydratingMask',
    link: 'onevita.eu/r/231fasvs-23b-gag-w25',
  },
  {
    id: 7,
    image: 'https://oneecosystem.blob.core.windows.net/vita/apps/images/night-cream.jpg',
    title: 'liposomalMetabolicActivatingMask',
    link: 'onevita.eu/r/52316vD-3b-gag-w25',
  },
];
