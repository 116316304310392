import { useEffect } from 'react';
import { startCase } from 'lodash-es';
import { getText } from '@veraio/strank';
import { Col, Button, Image, Row } from 'components';
import { ROUTES } from 'enums';
import { affiliateData } from './config';
import { affiliateContainer } from './styles';

const BecomeAnAffiliate = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div css={affiliateContainer}>
      <Image
        src="https://oneecosystem.blob.core.windows.net/vita/apps/images/silver-crystal.svg"
        className="header-image"
      />

      <div className="affiliate-header-container">
        <Image src="https://oneecosystem.blob.core.windows.net/vita/apps/images/vita-logo.svg" className="logo" />
        <h3 className="header-title">{getText('becomeVitaAffiliateUnlockPerks')}</h3>
        <p className="header-subtitle">{getText('joinOneVitaAffiliateProgram')}</p>
        <Button type="primary" className="apply-button" linkTo={ROUTES.BecomeAnAffiliateApply}>
          {getText('applyNow')}
        </Button>
      </div>

      <div className="about-program-container">
        <div className="about-program-info">
          <h3 className="about-title">{startCase(getText('aboutProgram'))}</h3>
          <p className="about-description">{getText('weAreThrilledToOfferYouAnExclusiveOpportunity')}</p>
          <Button type="secondary" className="about-button" linkTo={ROUTES.BecomeAnAffiliateApply}>
            {getText('applyNow')}
          </Button>
        </div>
        <Image
          src="https://oneecosystem.blob.core.windows.net/vita/apps/images/affiliate-women.jpg"
          className="affiliate-women-image"
        />
      </div>

      <div className="receive-perks-container">
        <Image
          src="https://oneecosystem.blob.core.windows.net/vita/apps/images/affiliate-sticker.svg"
          className="sticker-image"
        />

        <h3 className="about-title">{startCase(getText('receiveUnparalleledPerks'))}</h3>

        <Row gap={24} className="receive-info-container">
          {affiliateData?.map((el, ind) => (
            <Col xs={12} sm={12} md={6} lg={4} xl={3} key={ind}>
              <Image src={el.image} className="receive-image" />
              <h4 className="receive-title">{getText(el.title)}</h4>
              <p className="receive-description">{getText(el.description)}</p>
            </Col>
          ))}
        </Row>

        <div className="receive-horizontal-line" />

        <h4 className="ready-title">{getText('readyToBecomeOneVitaAffiliate')}</h4>

        <Button type="secondary" className="ready-button" linkTo={ROUTES.BecomeAnAffiliateApply}>
          {getText('applyNow')}
        </Button>
      </div>
    </div>
  );
};

export default BecomeAnAffiliate;
