import { theme } from 'styles';

export const discoverBeautyContainer = {
  padding: '3em 15.25em ',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
  flexWrap: 'wrap',

  '.action-button': {
    textTransform: 'uppercase',
    marginBottom: 36,
    backgroundColor: 'transparent',
    color: theme.gray500,
    border: `1px solid ${theme.grayLight}`,
    fontSize: 16,
    fontWeight: 400,
    width: 'fit-content',

    '&:active': { outline: 'none' },

    '@media (max-width: 767px)': {
      width: '100%',
    },
  },

  '.discover-beauty-first-col': {
    display: 'flex',
    flexDirection: 'column',
    margin: '0 32px 32px 0',
    maxWidth: '40%',

    '.discover-beauty-title': {
      fontSize: 38,
      fontWeight: 400,
      color: theme.black,
      marginBottom: 16,
    },

    '.discover-beauty-subtitle': {
      fontSize: 18,
      fontWeight: 300,
      color: theme.gray900,
      lineHeight: 1.5,
      marginBottom: 16,
    },

    '.first-image': {
      maxHeight: 520,
    },

    '@media (max-width: 767px)': {
      maxWidth: '100%',
      margin: '0 0 32px 0',
    },
  },

  '.discover-beauty-second-col': {
    display: 'flex',
    flexDirection: 'column',

    '.discover-beauty-second-image': {
      marginBottom: 32,
      maxHeight: 350,

      '@media (max-width: 767px)': {
        maxHeight: 550,
        width: '100%',
      },
    },

    '.discover-beauty-third-image': {
      maxHeight: 370,

      '@media (max-width: 767px)': {
        maxHeight: 550,
        marginBottom: 32,
      },
    },

    '@media (max-width: 767px)': {
      width: '100%',
      margin: '0 0 32px 0',
    },
  },

  '@media (max-width: 1199px)': {
    padding: '3em 6.25em',
  },

  '@media (max-width: 992px)': {
    padding: '3em',
  },

  '@media (max-width: 767px)': {
    padding: '3em',
  },
};
