import { useEffect, useState } from 'react';
import { getText } from '@veraio/strank';
import { Button, CheckButton, Dropdown, FileInput, Form, Image, Input, inputValidation, PhoneInput } from 'components';
import { useCountries } from 'stores';
import { affiliateFromContainer } from './styles';

const BecomeAnAffiliateForm = () => {
  const countries = useCountries();
  const [hasBusiness, setHasBusiness] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleCreateAffiliate = () => {
    setShowSuccess(true);
    window.scrollTo(0, 0);
  };

  const handleResendEmail = () => {};

  return (
    <div css={affiliateFromContainer}>
      {showSuccess ? (
        <div className="success-container">
          <Image
            src="https://oneecosystem.blob.core.windows.net/vita/apps/images/applause-icon.svg"
            className="success-icon"
          />
          <h3 className="success-title">{getText('youHaveSuccessfullyApplied')}</h3>
          <p className="success-subtitle">{getText('ourTeamWillReachOutWithin48Hours')}</p>

          <h4 className="not-receive-email">{getText('didNotReceiveEmail')}</h4>
          <p className="email-subtitle">{getText('pleaseCheckAllOfYouFolders')}</p>

          <Button small onClick={handleResendEmail} className="apply-button">
            {getText('resendEmail')}
          </Button>
        </div>
      ) : (
        <>
          <div className="affiliate-form-header">
            <div className="affiliate-form-header-info">
              <h3 className="header-title">{getText('applyForProgram')}</h3>
              <p className="header-subtitle">{getText('readyToBecomeOneVitaAffiliateApply')}</p>
            </div>
          </div>

          <Form onSubmit={handleCreateAffiliate} className="form-container">
            <Input
              horizontal
              required
              small
              formId="name"
              margin="0 0 1em"
              label={getText('yourName')}
              placeholder={getText('pleaseEnterYourName')}
            />

            <Input
              horizontal
              required
              small
              formId="email"
              margin="0 0 1em"
              label={getText('emailAddress')}
              placeholder={getText('pleaseEnterValidEmailAddress')}
              validate={inputValidation('required', 'email')}
            />

            <PhoneInput
              horizontal
              required
              formId="phone"
              margin="0 0 1em"
              label={getText('phoneNumber')}
              placeholder={getText('yourPhoneNumber')}
              codePlaceholder={getText('phoneCode')}
              className="phone-input"
            />

            <Dropdown
              horizontal
              required
              noClear
              withSearch
              formId="country"
              label={getText('country')}
              placeholder={getText('chooseCountry')}
              uniqueKey="id"
              displayKey="name"
              className="dropdown"
              options={countries}
            />

            <p className="kyc-title">{getText('idAndAddressVerification')}</p>

            <div className="upload-id-proof-container">
              <FileInput
                required
                notShowLabel
                accept="image/*"
                formId="idProofFront"
                label={getText('uploadIdFront')}
                id="idProofFront"
                className="upload-id-doc"
              />
              <FileInput
                required
                notShowLabel
                accept="image/*"
                formId="idProofBack"
                label={getText('uploadIdBack')}
                id="idProofBack"
              />
            </div>

            <FileInput
              required
              notShowLabel
              accept="image/*"
              formId="addressProof"
              label={getText('uploadAddressProof')}
              id="addressProof"
              className="address-proof"
            />

            <CheckButton value={hasBusiness} onChange={(val) => setHasBusiness(val)} className="check-button">
              {getText('iAmBusinessOptional')}
            </CheckButton>

            {hasBusiness && (
              <Input
                horizontal
                required
                small
                formId="businessName"
                margin="0 0 1em"
                label={getText('businessName')}
                placeholder={getText('enterYourBusinessName')}
              />
            )}

            {hasBusiness && (
              <Input
                horizontal
                required
                small
                formId="businessId"
                margin="0 0 1em"
                label={getText('uniqueIdNumber')}
                placeholder={getText('yourBusinessId')}
              />
            )}

            {hasBusiness && (
              <Input
                horizontal
                required
                small
                formId="bankAccountNumber"
                margin="0 0 1em"
                label={getText('bankAccountNumber')}
                placeholder={getText('enterYourBankAccountNumber')}
              />
            )}

            {hasBusiness && (
              <FileInput
                required
                notShowLabel
                accept="image/*"
                formId="bankStatement"
                label={getText('uploadBankStatementToVerify')}
                id="addressProof"
                className="address-proof"
              />
            )}

            {({ onSubmit }) => (
              <Button small onClick={onSubmit} className="apply-button">
                {getText('applyNow')}
              </Button>
            )}

            <div className="affiliate-form-info">
              <h3 className="form-info-title">{getText('questions')}</h3>
              <div className="form-info-description-container">
                <span className="form-info-description">{getText('haveQuestionsAboutOneVitaCosmetics')}</span>
                <a href="mailto: affiliates@onevita.com" className="touch-text">
                  affiliates@onevita.com
                </a>
                <span className="form-info-description">{getText('andWeWillGetBackToYouWithAllAnswers')}</span>
              </div>
            </div>
          </Form>
        </>
      )}
    </div>
  );
};

export default BecomeAnAffiliateForm;
