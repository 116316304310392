import { theme } from 'styles';

export const button = (isActive) => ({
  fontWeight: 500,
  color: theme.gray400,
  padding: '0px 8px',
  textTransform: 'uppercase',
  borderBottom: isActive ? `1px solid ${theme.black}` : 'none',

  '&:active': { outline: 'none', borderBottom: `1px solid ${theme.black}` },

  '&:hover': {
    borderBottom: `1px solid ${theme.black}`,
  },
});

export const navBarHeaderContainer = (isActive) => ({
  height: 60,
  backgroundColor: theme.white,
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: '1em 11.25em',
  position: 'sticky',
  top: 0,
  left: 0,
  right: 0,
  zIndex: 1000,

  '.buttons-container': {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',

    '.products': {
      width: '100%',
      position: 'relative',

      '.product-button': {
        width: '100%',
        cursor: 'pointer',
        minHeight: '4.8rem',
        display: 'inline-flex',
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: 0,
        lineHeight: 1,
        border: '1px solid transparent',
        opacity: 1,
        backgroundColor: theme.white,
        userSelect: 'none',
        transition:
          'color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out, width .4s ease-in-out',
        fontWeight: 500,
        color: theme.gray400,
        padding: '0px 8px',
        textTransform: 'uppercase',
        borderBottom: isActive ? `1px solid ${theme.black}` : 'none',

        '&:active': { outline: 'none', borderBottom: `1px solid ${theme.black}` },

        '&:hover': {
          borderBottom: `1px solid ${theme.black}`,
        },

        '.hovered-content-container': {
          position: 'absolute',
          top: 50,
          left: '-33vw',
          width: '100vw',
          backgroundColor: theme.white,
          border: `1px solid ${theme.secondary}`,
          color: theme.gray500,
          borderRadius: '0.25rem',
          boxShadow: theme.boxShadow,
          height: 620,
          padding: 24,
          visibility: 'hidden',
          opacity: 0,

          '.title': {
            width: '100%',
            textAlign: 'center',
            margin: '32px 0',
          },

          '.product-container': {
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            overflow: 'auto',

            '::-webkit-scrollbar': {
              display: 'none',
            },

            '.product': {
              display: 'flex',
              flexDirection: 'column',
              minWidth: 200,
              padding: 24,

              '.image': {
                objectFit: 'none',
                width: 200,
                height: 400,

                '@media (max-width: 992px)': {
                  width: 200,
                  height: 350,
                },
              },

              '.product-name': {
                textTransform: 'uppercase',
                fontWeight: 500,
                fontSize: 16,
                textAlign: 'center',
              },

              '&:hover': {
                cursor: 'pointer',
                backgroundColor: theme.secondary,
              },
            },
          },

          '@media (max-width: 1399px)': {
            maxWidth: 1100,
            transform: 'translate(-27%, 0%)',
            left: 10,
          },

          '@media (max-width: 1199px)': {
            maxWidth: 1000,
            margin: '0 32px',
            transform: 'translate(-30%, 0%)',
            left: '30%',
          },

          '@media (max-width: 992px)': {
            maxWidth: 800,
            margin: '0 32px',
            transform: 'translate(-26%, 0%)',
            left: '30%',
          },

          '@media (max-width: 992px) and (min-width: 767px)': {
            maxWidth: 600,
            margin: '0 32px',
            transform: 'translate(-20%, 0%)',
            left: '30%',
          },
        },
      },

      '.product-button:hover .hovered-content-container': {
        visibility: 'visible',
        opacity: 1,
      },
    },
  },

  '@media (max-width: 1199px)': {
    padding: '1em 6.25em',
  },

  '@media (max-width: 992px)': {
    padding: '1em',
  },
});

export const navBarLeftSideContainer = {
  display: 'flex',
  flexFlow: 'row nowrap',
  alignItems: 'center',
  whiteSpace: 'nowrap',

  '& > svg': {
    height: 36,
    width: 'auto',
  },

  '& > *': {
    margin: '0 2rem 0 0',
    cursor: 'pointer',
    '&:last-child': {
      marginRight: 0,
    },
  },
};

export const navBarRightSideContainer = {
  display: 'flex',
  flexFlow: 'row nowrap',
  alignItems: 'center',

  '.languages-container': {
    top: 66,
    left: 0,
  },

  '& > *': {
    marginRight: 16,
    cursor: 'pointer',

    '&:last-child': {
      marginRight: 0,
    },
  },
};
